import React from "react"

export default (props) => {
    const {
        id,
        alt,
        aspectRatio,
        backgroundColor,
        backgroundPreview,         
        sizes,
        devices,
        images,
        width,
        height,
        webpImages
    } = props;

    // let sizes = "";

    // if (phone) 
    //     sizes = "${file.phone}px, ${file.desktop}px";
    // else 
    //     sizes = "(max-width: 425px) ${file.phone}px, ${file.desktop}px";

    return (
        <div id={id} style={{            
            position: "relative", 
            width: "100%", 
            overflow: "hidden",
            paddingTop: aspectRatio + "%", 
            backgroundColor: backgroundColor || null,
            backgroundSize: "cover",
            backgroundImage: "none",
            backgroundImage: backgroundPreview ? "url("+backgroundPreview+")" : null,
        }}>
            <div style={{position: "absolute", top: "0px", width: "100%"}}>
                <picture>
                    <source 
                        type="image/webp" 
                        srcSet={webpImages.map((img, idx) => img + " " + sizes[idx] + "w").join(", ")}
                        sizes={sizes.join(", ")}
                    />
                    <img 
                        srcSet={images.map((img, idx) => img + " " + sizes[idx] + "w").join(", ")}
                        sizes={sizes.map((size, idx) => (devices[idx] == "phone" ? "(max-width: 425px) ":"") + size + "px").join(", ")}
                        src={images[0]}
                        loading="lazy"
                        decoding="async"
                        // width={width}
                        // height={height}
                        style={{width: "100%", objectFit: "cover"}}
                        alt={alt}
                        className="image"
                        onLoad={(e) => {
                            var p = document.getElementById(id);
                            
                            if (p) {
                                p.style.backgroundColor = null;
                                p.style.backgroundImage = null;
                            }
                        }}
                    />
                </picture>            
            </div>
        </div>
    );
}
