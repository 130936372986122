import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faChevronRight, faCaretRight } from "../../../SignMonkeyBuilder/node_modules/@fortawesome/free-solid-svg-icons"
import { isMobile } from "react-device-detect";

import Container from "../components/layout/Container"
import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import TrustPilot from "../components/TrustPilot"
import HoverButton from "../components/buttons/HoverButton"

import VideosPreviewImage from "../components/images/videos-preview"
import PhotosPreviewImage from "../components/images/photos-preview"
import MapPreviewImage from "../components/images/map-preview"

import { devices, storage, colors } from "../settings"

const Header = styled.div`
  display: flex;
  align-items: flex-end;

  @media ${devices.mobile} {
    display: block;
  }

  > div > p {
    margin-left: 20px;

    @media ${devices.mobile} {
      margin-left: 0px;
    }
  }

  div.trustpilot-widget {
    align-self: center;

    @media ${devices.mobile} {
      margin-top: 30px;
    }
  }

  img {
    @media ${devices.mobile} {
      display: block;
      margin: 0px auto;
      width: 50%;    
    }
  }
`
const Content1 = styled.div`
  border-top: 3px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-image: url(${storage.url}2020/tiles/grey3.jpg);
  padding-top: 25px;
  padding-bottom: 25px;

  @media ${devices.mobile} {
    margin: 0px -10px;
  }
`
const Content2 = styled.div`
  padding: 20px; 0px;

  ul {
    list-style-type: none;
    margin: 20px 0px;
    padding: 0px;

    h2 {
      font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
      font-size: 24px;
      margin-bottom: 0px;
      font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }
    p {
      margin-left: 20px;
    }

    li {
      margin-bottom: 15px;
    }
  }
`

const Tiles = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 20px;

    @media ${devices.mobile} {
      margin:10px;
    }

    > * {
      flex-basis: 30%;
      color: black;
    }

    h3 {
      margin-bottom: 4px;
    }

    @media ${devices.mobile} {
      > * {
        flex-basis: 100%;
      }
    }
`
const Tile = styled(Link)`
  margin-bottom: 15px;
  background-color: white;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0px 6px 10px #ccc;  
  display: block;
  
  &.image {
    padding: 0px;
    position: relative;

    > div:nth-child(2) {
      position: absolute;
      right: 0px;
      bottom: 0px;
      left: 0px;
      padding: 5px 10px;
      font-size: 24px;
      color: white;
      border-radius: 0px;
      background-color: rgba(255,255,255,.2);

      &.map {
        background-color: rgba(0,0,0,.3);
      }
    }
  }
  &:hover {
    transform: scale(1.025);
  }

  img {
    margin-bottom: 4px;
    font-size: 18px;
  }
  h2 {
    margin: 10px 0px;
    font-size: 26px;
    color: ${colors.orange};
    font-weight: 600;
    text-align: center;
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  }
  div {
    text-align: right;
    color: ${colors.blue};
    border-radius: 4px;
  }

  @media ${devices.mobile} {
    height: auto;
  }
`

const WhyPage = () => {
  return (
  <>
    <SEO page="why" title="Why Buy Your Sign at Sign Monkey?" />
    <Page
        padding={0}
        breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Why SignMonkey", url:null}
        ]}
        containerProps={{
          mode: "full"
        }}
    >
      <Container>
        <Header>
          <div>
            <PageHeader title="Why SignMonkey?" />
            <p>
              When we built SignMonkey.com we threw away the rulebook while pulling from our 30+yrs of experience 
              to build a solution for the underserved sign consumer. Upfront and fast pricing is important to our 
              solution. Our customers have told us that they received their SignMonkey sign before the other sign 
              companies got back with a price.
            </p>
            <p>
              <strong>Learn more about designing, buying and installing signs in our</strong>
              &nbsp;
              &nbsp;
              <Link to="/blog/">
                <HoverButton
                  label="Sign Blog"
                />
              </Link>
              &nbsp;
              &nbsp;
              <Link to="/signencyclopedia/">
                <HoverButton
                  label="Sign Encyclopedia"
                />
              </Link>              
            </p>
          </div>
          <div>
            <GoogleImage root src="2020/tony.jpg" required lazy alt="Sign Expert" width={isMobile ? 136:150} style={{maxWidth:"initial"}} height={isMobile ? 164:141} />
          </div>
          <div>
            <TrustPilot height={80} />
          </div>
        </Header>
      </Container>

      <Content1>
        <Container>
          <Tiles>
            <Tile to="/why/fast-production/">
              <h2>Built in 5 Days</h2>
              <p>
                  Our lean manufacturing methods allow us to build your sign in just 5 days.  Nothing sets around in our shop
                  waiting on materials or getting damaged.
              </p>
              <div>
                Learn More{' '}
                <FontAwesomeSvgIcon icon={faChevronRight} />
              </div>
            </Tile>
            <Tile to="/why/easy-installation/">
              <h2>Easy Installation</h2>
              <p>
                Our products are designed to be installed by anyone with basic handyman skills.  We know it is difficult
                to find a local sign company to do the installation.
              </p>
              <div>
                Learn More{' '}
                <FontAwesomeSvgIcon icon={faChevronRight} />
              </div>
            </Tile>
            <Tile to="/why/pricing/">
              <h2>Instant Online Pricing with Free Shipping</h2>
              <p>
                Don't wait a week for a sign price, use our Sign Price Builder to get a Wholesale Direct price now.
              </p>
              <div>
                Learn More{' '}
                <FontAwesomeSvgIcon icon={faChevronRight} />
              </div>
            </Tile>

            <div>
              <h3>Videos</h3>
              <Tile to="/why/videos/" className="image">
                  <VideosPreviewImage />
                  <div>
                    Watch Videos{' '}
                    <FontAwesomeSvgIcon icon={faCaretRight} />
                  </div>
              </Tile>
            </div>
            <div>
              <h3>Photos</h3>
              <Tile className="image" to="/why/photos/">
                  <PhotosPreviewImage />
                  <div>
                    See Photos{' '}
                    <FontAwesomeSvgIcon icon={faCaretRight} />
                  </div>
              </Tile>
            </div>
            <div>
              <h3>Find a Sign Near You</h3>              
              <Tile to="/map/" className="image">
                  <MapPreviewImage />
                  <div className="map">
                    Find Signs{' '}
                    <FontAwesomeSvgIcon icon={faCaretRight} />
                  </div>
              </Tile>
            </div>
          </Tiles>
        </Container>
      </Content1>
      <Content2>
        <Container>
          <ul>
          <li>
            <h3>Why did we develop SignMonkey?</h3>
            <p>
              Customer service and professionalism has alway been lacking in the sign business. There are also issues with production 
              time and quality inconsistencies. We develop SignMonkey to address these issues and to make a pleasurable customer experience.
            </p>
          </li>
          <li>
            <h3>What do we do differently?</h3>
            <p>
              Practically everything, We have a complete LEAN production system. That is how our products are built in just 5 days. It 
              is also how we keep our prices low while increasing quality. We will also give you a live price in our Design &amp; Price App or 
              if you have your own design, we will have you a price within 24hrs.
            </p>
          </li>
          <li>
            <h3>What problems are we solving?</h3>
            <p>
              In a word “Consistency”, from pricing to quality to fast production, our exclusive production techniques and proven 
              methods to assure you will receive the same high quality product every time.
            </p>
          </li>
          <li>
            <h3>What Makes Our Product Different?</h3>
            <p>
              Our products are built with time proven methods. With our system all our products are built the same way every time. Our 
              customers know what to expect from SignMonkey. This is why we have such high repeat customers and referrals.
            </p>
          </li>
        </ul>
        </Container>
      </Content2>
    </Page>
  </>
)}

export default WhyPage
