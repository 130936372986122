
import React from "react"

import Image from "../Image"

export default ({size}) => (
    <Image 
        id="img-videos-preview"
        alt="SignMonkey Videos"
        backgroundColor={"#412825"}
        backgroundPreview={"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAUACgMBEQACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/AP4/fhb8Rnt73wvbz+A/hXqFvqWjahpkqXvw68NTOsK6TcaU11vayMcmqs+mWeoR6pJC15b3/wBqureSO4v72afwcXh3KpWqLEYqHspJcsMRNQftbVL2T05VDkiotJRnPTVcv6jkueSwtHA0nk/DmJ9pgqqVTFZFga1VRoKeF5pSnBxq15ynCvLEVYSqe1ow6Sre177X/iCuo67rWoL8P/hPpy32rajeLp+mfDXwnaabYLdXk04s9PtV01ltrG1D+RaW4ZhDAkcYJC5pwhUjCEfb1nyxirucm3ZJXbvq31fcrGZhDE4zFYn+y8mw/wBYxNev7DDZbh6OGo+1qyqeyw9JRapUKfNyUqabUKajFN2P/9k="}
        aspectRatio={53}  
        width={370} 
        height={199.453125} 
        sizes={[390, 370]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/videos-preview-390.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/videos-preview-370.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/videos-preview-390.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/videos-preview-370.jpg?v25"
        ]}
    />   
)
