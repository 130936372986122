
import React from "react"

import Image from "../Image"

export default ({size}) => (
    <Image 
        id="img-photos-preview"
        alt="SignMonkey Photos"
        backgroundColor={"#fb5734"}
        backgroundPreview={"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAUACgMBEQACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/APxF+Pn7S2nfB7UfEvwz8OfCvRte/wCED1G00yy8T+NPE2v3usalJayyzaffarH4U/4Qy0upreCOKC5ieNrfUpEN5eRyXE1w0v5JU4Aj/aOIxlLM/qlLEqqqVLD4bE1KuFhWl7SrClLMczzDARUqlSo4wpZZSo04SjTp0YqnBr9s/wCIq4F5PleX43hKlm+OwDwssRicyzSlh8vzCvgFGGDrVsJw9k+QZtz0qdGg51avEOIxeJxFJ4nE4utOrUjLzRvH9hfE3t74E8HzXl4TdXc39l2/725uD508n7yCaT95K7N+8llfn5pHbLHvw/D7pYehShnefKFOjSpxSxeHSUYQjGKSWDskkloj5PGeJTr4vFV58DcBqdbE16s7ZdnVuapVlOVr8QN2u3a7btu2f//Z"}
        aspectRatio={53}  
        width={370} 
        height={199.453125} 
        sizes={[390, 370]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/photos-preview-390.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/photos-preview-370.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/photos-preview-390.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/photos-preview-370.jpg?v25"
        ]}
    />   
)
