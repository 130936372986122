import { navigate } from 'gatsby';
import React from 'react';

const TrustBox = (props) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div style={{position:"relative"}}>
      <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget" // Renamed this to className.
          data-locale="en-US" 
          data-template-id={props.type && props.type == "mini" ? "53aa8807dec7e10d38f59f32":"5419b732fbfb950b10de65e5"}
          data-businessunit-id="5a31a4d4b894c90d84225a75" 
          data-style-height={(props.height || 110) + "px"}
          data-style-width="100%" 
          data-theme="light"
      >
          <a href="https://www.trustpilot.com/review/signmonkey.com" target="_blank">
              Trustpilot
          </a> 
      </div>
      <div 
        onClick={(e) => {
          navigate("/why/reviews");
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{height:(props.height || 110) + "px", position:"absolute", top:"0px", width:"100%", cursor:"pointer"}}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default TrustBox;