
import React from "react"

import Image from "../Image"

export default ({size}) => (
    <Image 
        id="img-map-preview"
        alt="SignMonkey Signs Map Preview"
        backgroundColor={"#a4dcfc"}
        backgroundPreview={null}
        aspectRatio={53}  
        width={350} 
        height={188.23529411764704} 
        sizes={[390, 350]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/map-preview-390.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/map-preview-350.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/map-preview-390.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/map-preview-350.jpg?v25"
        ]}
    />   
)
